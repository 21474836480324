@import "~antd/es/style/themes/default.less";

.form {
  position: absolute;
  top: 45%;
  left: 50%;
  margin: -175px 0 0 -160px;
  width: 372px;
  height: 520px;
  padding: 36px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);

  button {
    width: 100%;
  }

  p {
    color: @error-color;
    text-align: center;
    margin-top: 16px;
    font-size: 12px;
    display: flex;
    justify-content: center;
  }
}

.logo {
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 96px;
    margin-right: 8px;
  }

  span {
    vertical-align: text-bottom;
    font-size: 16px;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 700;
    color: @primary-color;
  }
}
