.popoverMark {
  vertical-align: super;
}

.flag {
  width: 20px;
}

.fc {
  width: 100%;
}

.hc {
  width: 50%,
}

.tc {
  width: 33%
}

.port_table {
  td {
    text-align: left;
  }
}