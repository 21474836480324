.headrow {
    flex-direction: row;
}

.banner {
  margin-top: 12px;
}

.content {
  padding: 0 24px;
}

@media only screen and (max-width: 768px) {
  .headrow {
    flex-direction: column;
  }

  .banner {
    max-width: 100%;
  }

  .content {
    padding: 0 12px;
  }
}
  