.header {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  background: #ffffff;
}
.balance {
  padding: 0 20px;
}
.flag {
  width: 20px;
}
.avatar {
  margin-left: 8px;
}
