$screen-xs: 480px;
$screen-md: 768px;
$screen-xl: 1200px;

.layout {
  min-height: 100vh;
}

.sider {
  & .ant-layout-sider-trigger {
    display: none;
  }
  
  &.ant-layout-sider {
    flex: 0 0 auto !important;
    max-width: none !important;
    min-width: 0 !important;
    width: 15rem !important;
    z-index: 999;
  }
  
  &.ant-layout-sider-collapsed {
    width: 0 !important;
    
    @media screen and (min-width: $screen-md) {
      width: 5rem !important;
    }
  }
}
.ant-layout-header{
    color: white;
    background: white;
}




.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  width: 60%;
}

.logo_div {
  display: flex;
  flex-direction: row;
  align-items: center; 
  margin-left: 20px;
}

.CloseOutlined{
  display: none;
  @media screen and (max-width: $screen-md){
    display: block;
    font-size: x-large;
    color: white;
  }
}

.trigger {
    @media screen and (min-width: $screen-xl) {
      display: none;
    }
    @media screen and (max-width: $screen-md) {
        margin: 15px;
    }
}